import styled, { css } from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    background: url("/images/img-doubler-2x.svg") calc(50% - 15px)
        calc(100% - 105px) / 280px auto no-repeat;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        margin-bottom: 75px;

        background-position: calc(50% + 100px);
        background-size: 450px auto;
    }

    ${media.lgUp} {
        background-size: auto;
    }

    ${media.xlUp} {
        gap: 0 45px;
        margin-bottom: 65px;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;

    background: url("/images/img-doubler-2x.svg") center 80px / 280px auto
        no-repeat;

    ${media.lgUp} {
        background-size: 300px auto;
    }

    ${media.xlUp} {
        background-size: 320px auto;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    flex: 0 0 auto;
    width: 100%;
    max-width: 240px;
    margin: 0 auto;

    text-align: center;

    ${media.mdUp} {
        max-width: ${({ fullWidth }) =>
            fullWidth ? "calc(50% - 10px)" : "275px"};
    }

    ${media.xlUp} {
        max-width: 500px;
    }
`;

export const Title = styled.h3`
    max-width: 80%;
    margin: 0 0 10px;

    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);

    ${media.mdUp} {
        max-width: unset;
    }

    ${({ as }) =>
        as === "h2"
            ? css`
                  ${media.mdUp} {
                      margin: 0 0 5px;

                      font-size: var(--font-size-h2);
                      line-height: var(--line-height-h2);
                  }
              `
            : css`
                  ${media.mdUp} {
                      margin: 10px 0;
                  }
              `};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${media.mdUp} {
                align-items: flex-start;
                text-align: left;
            }
        `}
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 25px;

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            max-width: 90%;

            ${media.mdUp} {
                max-width: 80%;
            }
        `}
`;
