import provinces from "constants/provinces";

import { generateSlug, isNotEmpty } from "./utils";

export function generateMerchantSlug(province, city, slug) {
    if (!province || !city) {
        return `/restaurants/${slug}`;
    }

    const provinceSlug = generateSlug(province);
    const citySlug = generateSlug(city);

    return `/restaurants/${provinceSlug}/${citySlug}/${slug}/`;
}

export function buildMerchantsFilterQuery(params, category) {
    const { q: query, city, province, cat, theme } = params;
    const provinceCode = province?.toUpperCase();
    const variables = {};
    const filter = {};

    if (provinceCode) {
        variables.slug = provinceCode;
    }

    if (city) {
        variables.slug = city;
    }

    if (query && !city && !province) {
        filter.province = { like: query };
        filter.city = { like: query };
        filter.title = { like: query };
    }

    if (cat?.length > 0) {
        filter.theme = {
            in: cat
        };
    }

    if (theme?.length > 0) {
        let categories = [];

        if (cat) {
            categories = Array.isArray(cat) ? cat : [cat];
        }

        categories.push(category);

        filter.theme = {
            in: [...new Set(categories)]
        };
    }

    return { ...variables, filter };
}

export function filterMerchants(merchants, onlyValidAddress = false) {
    let edges = [];

    if (!merchants || !Array.isArray(merchants?.edges)) {
        return undefined;
    }

    edges = merchants.edges.filter(merchant => {
        if (merchant.__typename !== "Restaurant") {
            return false;
        }

        if (onlyValidAddress) {
            const { provinceCode, city } = merchant.address;
            return isNotEmpty(provinceCode) && isNotEmpty(city);
        }

        return true;
    });

    return { ...merchants, edges };
}

export function getProvinceName(name) {
    return provinces.find(item => item.id === name)?.label || name;
}
