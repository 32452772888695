import PropTypes from "prop-types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import IconGps from "components/ui/icons/IconGps";

import { VisuallyHidden } from "theme/mixins";

import { Location } from "./MyLocation.styled";

function MyLocation({ name, setSuggestions, position }) {
    const { setError } = useFormContext();
    const [loading, setLoadingGPS] = useState(false);

    function handleGeocode(result, status) {
        if (status === "OK") {
            const suggestions =
                result?.filter(
                    address => address.address_components.length > 4
                ) ?? [];

            setSuggestions(suggestions);
        }

        if (status !== "OK") {
            setError(name, {
                type: "manual",
                message: "Er was een fout bij het ophalen van suggesties"
            });
        }
        setLoadingGPS(false);
    }

    function fetchNavigatorLocation() {
        navigator.geolocation.getCurrentPosition(
            async geolocation => {
                const lat = parseFloat(geolocation.coords.latitude);
                const lng = parseFloat(geolocation.coords.longitude);

                const query = `/api/google-maps/geocode/?latlng=${lat},${lng}`;
                const response = await fetch(query);
                const data = await response.json();

                handleGeocode(data.results, data.status);
            },
            error => {
                let message;
                switch (error.code) {
                    case 1:
                        message =
                            "Geolocatie wordt door de browser geblokkeerd";
                        break;
                    case 2:
                        message = "Geen locatie beschikbaar";
                        break;
                    case 3:
                        message =
                            "Er was een fout bij het ophalen van uw geolocatie";
                        break;
                    default:
                        break;
                }

                if (message) {
                    setError(name, {
                        type: "manual",
                        message
                    });
                    setLoadingGPS(false);
                }
            }
        );
    }

    function onGPSClick() {
        if (navigator.geolocation) {
            setLoadingGPS(true);
            if (navigator?.permissions) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then(status => {
                        if (
                            status.state === "granted" ||
                            status.state === "prompt" ||
                            status.state === "denied"
                        ) {
                            fetchNavigatorLocation();
                        }
                    })
                    .catch(() => {
                        setError(name, {
                            type: "manual",
                            message: "Geblokkeerde geolocatie"
                        });
                        setLoadingGPS(false);
                    });
            }

            if (!navigator?.permissions) {
                fetchNavigatorLocation();
            }
        }

        if (!navigator?.geolocation) {
            setError(name, {
                type: "manual",
                message: "Geolocatie niet beschikbaar"
            });

            setLoadingGPS(false);
        }
    }

    return (
        <Location
            type="button"
            onClick={() => onGPSClick()}
            isLoading={!!loading}
            title="Gebruik mijn locatie"
            aria-label="Gebruik mijn locatie"
            position={position}
        >
            <IconGps
                size={position === "top" ? 16 : 26}
                fill="var(--color-primary)"
                aria-hidden="true"
            />
            {position === "top" ? (
                "Gebruik mijn locatie"
            ) : (
                <VisuallyHidden>Gebruik mijn locatie</VisuallyHidden>
            )}
        </Location>
    );
}

MyLocation.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.oneOf(["inline", "right", "top"]),
    setSuggestions: PropTypes.func.isRequired
};

MyLocation.defaultProps = {
    position: "inline"
};

export default MyLocation;
