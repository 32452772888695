export default [
    {
        label: "Drenthe",
        id: "dr"
    },
    {
        label: "Flevoland",
        id: "fl"
    },
    {
        label: "Friesland",
        id: "fr"
    },
    {
        label: "Gelderland",
        id: "ge"
    },
    {
        label: "Groningen",
        id: "gr"
    },
    {
        label: "Limburg",
        id: "li"
    },
    {
        label: "Noord Brabant",
        id: "nb"
    },
    {
        label: "Noord Holland",
        id: "nh"
    },
    {
        label: "Overijssel",
        id: "ov"
    },
    {
        label: "Utrecht",
        id: "ut"
    },
    {
        label: "Zuid Holland",
        id: "zh"
    },
    {
        label: "Zeeland",
        id: "ze"
    }
];
