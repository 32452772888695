import PropTypes from "prop-types";

function IconSearch({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M13,25.53a11.79,11.79,0,1,1,11.79,11.8A11.77,11.77,0,0,1,13,25.53ZM38.75,37.66,38,37a17,17,0,1,0-1.83,1.84l.7.74V41.6L49.14,53.83a1.24,1.24,0,0,0,1.75,0l2.16-2.16a1.26,1.26,0,0,0,0-1.76L40.82,37.66Z" />
        </svg>
    );
}

IconSearch.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconSearch.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconSearch;
