import styled from "styled-components";

export const SuggestionsList = styled.ul`
    position: relative;
    top: -1px;
    left: -1px;
    z-index: 9;

    width: calc(100% + 2px);
    margin: 0;
    padding: 0;
    overflow: hidden;

    list-style-type: none;
    background-color: var(--color-white);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    box-shadow: 0 6px 7px 0 rgba(49, 49, 62, 0.19);

    margin-block-start: 0;
    padding-inline-start: 0;
`;
