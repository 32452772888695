import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import { SuggestionsList } from "./Suggestions.styled";

const Suggestion = dynamic(() => import("./suggestion/Suggestion"));

function Suggestions({
    name,
    input,
    suggestionIsOpen,
    suggestions,
    onClick,
    limit
}) {
    const emptySuggestions = suggestions?.length === 0;

    return !suggestionIsOpen ? null : (
        <SuggestionsList
            emptySuggestions={emptySuggestions}
            inputFocus={suggestionIsOpen}
        >
            {!emptySuggestions &&
                suggestions
                    .slice(0, limit)
                    .map(suggestion => (
                        <Suggestion
                            key={suggestion.id}
                            onClick={() => onClick(suggestion)}
                            name={name}
                            description={suggestion.description}
                            string={input}
                        />
                    ))}
        </SuggestionsList>
    );
}

Suggestions.propTypes = {
    name: PropTypes.string.isRequired,
    input: PropTypes.string,
    suggestionIsOpen: PropTypes.bool.isRequired,
    suggestions: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    limit: PropTypes.number
};

Suggestions.defaultProps = {
    onClick: () => {},
    input: "",
    limit: 5
};

export default Suggestions;
